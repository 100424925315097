.PrivacyContainer{
    padding: 10px;
    text-align: left;
    max-height: 77vh;
    overflow-y: auto;
}

.PrivacyContainer h3{
    margin-bottom: 6px !important;
}

.PrivacyContainer p strong{
    margin-bottom: 8px;
    display: inline-block;
}

.NoImageContainer{
    height: 70vh;
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NoImageContainer img{
    width: 228px;
}