.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid  rgb(192, 106, 120);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}

.loader-text {
  margin-left: 20px;
  font-size: 24px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media (max-width:2561px) {
  .loader{
    width: 30px;
    height: 30px;
  }
}

@media (max-width:1441px) {

  .loader{
    width: 25px;
  height: 25px;
  }
 }
