.paginationContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.paginationInfo {
  font-size: 14px;
  color: rgba(107, 114, 128, 1);
}

.paginationControls {
  display: flex;
  align-items: center;
}

.opacSvg {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.prevButton,
.nextButton {
  border: none;
  background: transparent;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.prevButton:hover,
.nextButton:hover {
  color: #fff;
  border-radius: 4px;
}

.prevButton:focus,
.nextButton:focus {
  outline: none;
}

.currentPage {
  font-size: 16px;
  font-weight: bold;
  margin: 0 10px;
  color: rgba(197, 30, 58, 1);
}

.prevButton[disabled],
.nextButton[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.paginationControls {
  display: flex;
  align-items: center;
}

.prevButton,
.nextButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.opacSvg {
  opacity: 0.5;
  pointer-events: none;
}

.reactPaginate {
  margin: 5px 0;
}

.reactPaginate li{
  display: inline-block;
  border: 1px solid rgb(224, 224, 224);
  color: #000;
  cursor: pointer;
  margin-right: 3px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pageItem {
  margin: 0 5px;
  cursor: pointer;
  padding: 5px;
  background-color: #f0f0f0;
}

.active {
  font-weight: bold;
}

.break-me {
  cursor: default;
}

@media (max-width:991px) {
.paginationInfo{
  font-size: 12px;
}  
.currentPage{
  font-size: 14px;
}
}