.HeadingSection {
  /* padding: 8px 12px 12px 12px; */
  padding: 14px 12px 14px 12px;
  text-align: left;
  border-bottom: 1px solid #d8d0d0;
  min-width: 80%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeadingSection h2 {
  font-size: 25px;
  font-weight: 600;
}

.Btn {
  background: rgba(197, 30, 58, 1);
  color: #ffffff;
  font-size: 14px;
  border: none;
  box-shadow: 0px 1.06px 2.12px 0px #0000000d;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: 500;
  letter-spacing: 1px;
}

.Btn2 {
  color: #ffffff;
  font-size: 14px;
  border: rgba(197, 30, 58, 1);
  /* box-shadow: 0px 1.06px 2.12px 0px #0000000d; */
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: 500;
  letter-spacing: 1px;
}

.headingBtn {
  display: flex;
  gap: 20px;
  align-items: center;
}

.headingBtn h3 {
  font-size: 18px;
  font-weight: 600;
}

.Cancelled {
  font-size: 14px;
  font-style: italic;
}

.CancelledCircle {
  display: inline-block;
  width: 16px; 
  height: 16px; 
  background-color: rgb(230 190 196);
  border-radius: 50%;
  vertical-align: middle; 
}

@media (max-width: 2561px) and (min-width: 1991px) {
  .HeadingSection h2 {
    font-size: 33px;
  }
  .Btn{
    font-size: 22px;
  }
}

@media (max-width: 1200px) {
  .Btn {
    padding: 6px 10px;
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .HeadingSection {
    padding: 8px 12px;
  }
  .HeadingSection h2 {
    font-size: 22px;
  }
}

@media (max-width: 360px) {
  .HeadingSection {
    padding: 8px 12px;
  }
}
