@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
.App {
  text-align: center;
  position: relative;
}
.inter-normal {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "inter-normal", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px !important;
}

ul {
  list-style: none;
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}

.mainB2b {
  height: 100vh;
}

.accordion-button:not(.collapsed) {
  background-color: rgba(255, 241, 243, 1) !important;

  margin: 0px !important;
}

.accordion-button:focus,
.accordion-button:focus-visible {
  border-color: rgb(246 238 215) !important;
}

.OrderHistorySEtSearch .dropdown-toggle {
  border: none !important;
  background: transparent !important;
  color: #6b7280;
}
.form-control:focus,
.form-select:focus {
  box-shadow: none !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"] {
  color: #6b7280;
  font-size: 12px;
  letter-spacing: 1px;
  max-width: 95% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

input[type="search"]:not(:placeholder-shown) {
  color: #6b7280;
}

::placeholder {
  color: #6b7280;
  font-size: 12px;
  letter-spacing: 1px;
  max-width: 95% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

:-ms-input-placeholder {
  color: #6b7280;
  font-size: 12px;
  letter-spacing: 1px;
  max-width: 95% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

::-ms-input-placeholder {
  color: #6b7280;
  font-size: 12px;
  letter-spacing: 1px;
  max-width: 95% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

select {
  color: #6b7280 !important;
  font-size: 12px !important;
  letter-spacing: 1px !important;
}

select option {
  padding: 4px 8px !important;
  font-size: 15px;
}

select:focus {
  outline: none;
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add subtle shadow */
  background-color: #f9f9f9; /* Slight background color change on focus */
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
}
.order-summary-box .modal-dialog {
  width: 800px !important;
  min-width: 800px !important;
}

.tooltip-inner {
  background-color: rgb(175, 80, 80) !important;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(175, 80, 80) !important;
}

.modal-body-scrollable {
  max-height: 400px !important;
  overflow-y: auto !important;
}

.adjustViewModal .modal-dialog {
  width: 80vw !important;
  max-width: 80vw !important;
}

.adjustViewModal .modal-body-scrollable {
  max-height: none !important;
  overflow-y: none !important;
}

@media (max-width: 991px) {
  .store-location-box .modal-dialog {
    width: 781px !important;
    min-width: 681px !important;
  }
  .cartText{
    display: none;
  }
}

@media (max-width: 360px) {
  .toast {
    width: 88px !important;
    margin: 2px !important;
  }
}


