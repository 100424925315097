.wrapper{
    height: 75vh;
    width: 75vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
}

.wrapper h1{
    font-size: 56px;
    letter-spacing: 1rem;
}

.wrapper h6{
    font-size: 20px;
    letter-spacing: 0.3rem;
}