.customdropdown {
  position: relative;
  width: 304px !important;
  height: 36px !important;
}

.dropdownheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  /* background-color: #f0f0f0; */
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  background-color: #fff;
}

.dropdownlist {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 200px; /* Adding a max-height */
  overflow-y: auto;
}

.dropdownitem {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
}

.dropdownitem:hover,
.dropdownitem.selected {
  background-color: #fff1f3;
}

.selecteditem {
  flex: 1;
  font-size: 14px;
  max-width: 259px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.placeholder {
  color: #888;
  font-size: 12px;
}

@media (max-width: 2561px) and (min-width: 1991px) {
  .customdropdown {
    width: 390px !important;
  }

  .selecteditem {
    max-width: 340px;
    font-size: 20px;
  }

  .dropdownitem {
    font-size: 18px;
  }



}

@media (max-width: 1200px) {
  .customdropdown {
    width: 195px !important;
  }

  .dropdownheader {
    padding: 3px 5px;
  }
  .selecteditem {
    font-size: 12.5px;
  }
}

@media (max-width: 991px) {
  .selecteditem {
    font-size: 11.5px;
  }

  .customdropdown {
    width: 102px !important;
    display: flex;
    align-items: center;
    height: 27px !important;
  }

  .dropdownheader {
    width: 100%;
    height: 100%;
  }
}
