.FooterWrapper {
  background: rgba(235, 235, 235, 1);
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 5px 0px;
  font-size: 12px;
}

.FooterLinks {
  color: rgba(107, 114, 121, 1);
  display: flex;
}

.FooterLinks li{
    border-right: 1px solid rgba(107, 114, 121, 1);
  padding-right: 5px;
  list-style: none;
  text-decoration: none;
}

.FooterLinks li h6{
    text-decoration: none;
    padding-right: 15px;
    font-size: 13px;
    cursor: pointer;
    color: rgba(107, 114, 121, 1);
    padding-left: 15px;
}
.FooterCopyright{
  color: rgba(107, 114, 121, 1);
}
