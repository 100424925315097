.EditDetailWrapper {
  padding: 18px;
  margin: 2rem auto;
  width: 80%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.FormContainer {
  display: flex;
  flex-wrap: wrap;
}

.FormField {
  margin-bottom: 15px;
  width: 48%;
  margin-right: 4%;
  text-align: justify;
}

.FormField:nth-child(2n) {
  margin-right: 0;
}

.FormField label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
  font-size: 12px;
  color: rgba(107, 114, 128, 1);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.FormField input {
  width: 90%;
  padding: 3px 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: rgba(107, 114, 128, 1);
}

.SaveButton {
  width: 34%;
  padding: 10px;
  color: #fff;
  margin: 0 39%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgba(197, 30, 58, 1);
}

.SaveButton:hover {
  background-color: rgb(162 38 59);
}
