.CustomerDetailTable {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #e7e7e7;
    overflow-x: auto;
    height: 78vh;
  }

  .disabledIcon {
    color: gray; /* Make it appear disabled */
    pointer-events: none; /* Disable interaction */
    opacity: 0.9;
    
  }
  