.MainHeaderWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  align-items: center;
  border-bottom:1px solid #d8d0d0
}

.MainHeaderWr {
  gap: 1.5rem;
  width: 33%;
}

.profileSToreDropdown {
  height: 119px !important;
}

.MainHeaderLogo img {
  width: 113px;
  cursor: pointer;
  height: 51px;
}

.searchContainer {
  display: flex;
  width: 33%;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
}

.searchContainer .inputSearch {
  border: 1px solid rgba(231, 231, 231, 1);
  border-radius: 0px !important;
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}
.searchContainer .inputSearch:focus {
  box-shadow: none;
}

.searchContainer .searchButton {
  border: 1px solid rgba(197, 30, 58, 1);
  background-color: rgba(197, 30, 58, 1);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 4px 28px 4px 25px;
}

.searchContainer .searchButton span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.searchContainer .searchButton i {
  color: #fff;
  font-size: 14px;
}

.searchContainer .searchButton p {
  color: #fff;
  margin-bottom: 0rem;
}

.ProfileInfo {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  width: 33%;
  justify-content: flex-end;
}

.CartButton {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.CartButton p {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.CartButton p i {
  font-size: 30px;
}

.ProfileButton {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.avatarBox {
  background-color: #c51e3a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
}

.ProfileButtonText {
  display: flex;
  flex-direction: column;
  margin-bottom: 0rem;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
}
.ProfileButton span i {
  font-size: 22px;
  color: #fff;
}
.ProfileButtonText span {
  display: block;
}

.ProfileButton .socialLInks span i {
  font-size: 22px;
  color: #525252;
}

/*------ sidebar css------*/
.SideBarWrapper {
  background-color: #ffffff;
  min-width: fit-content;
  display: flex;
  width: 15%;
  transition: width 0.3s;
  position: relative;
}

.SideBarContainerScroll {
  overflow-y: auto;
  max-height: 79vh;
}

.SideBarWrapper.open {
  width: 15%;
}

.SideBarWrapper.closed {
  width: 4%;
}

.RightContent {
  transition: margin-left 0.3s;
}

.RightContent.sidebarClosed {
  width: 94%;
  margin-left: 1rem;
}

.SideBarContainer {
  border-right: 1px solid #e7e7e7;
  width: 100%;
}

.SideBarMenu span {
  display: flex;
}

.sidebarList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.show_menu {
  display: block;
}

.hide_menu {
  display: none;
}

.active {
  color: #c51e3a !important;
  border-right: #c51e3a;
  background-color: rgba(255, 241, 243, 1);
}

.mainContainer {
  display: flex;
  height: 88vh;
  width: 100%;
  overflow: hidden;
}

.RightContent {
  width: 85%;
  position: relative;
}

.SideBarContainer button,
.AccordionSection button {
  width: 100%;
  border: none;
  box-shadow: none;
  padding: 6px 10px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 15px; */
}

.AccordionSection button span {
  font-size: 14px;
  font-weight: 600;
  color: #5d6c87;
}

.toggleButton {
  z-index: 100;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 24px;
  padding-left: 17px !important;
}

.opentoggleButton {
  justify-content: flex-end !important;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 24px;
  z-index: 100;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding-right: 0.5rem !important;
}
.opentoggleButton i,
.toggleButton i {
  color: black;
}

.AccordionSection p {
  display: flex;
  gap: 10px;
  padding-left: 7px !important;
}

.AccordionSection button {
  padding-left: 7px !important;
}

.AccordionSection {
  margin-bottom: 20px;
}

.logout {
  display: flex;
  padding-left: 1px !important;
}
.backStore {
  display: flex;
  padding-left: 1px !important;
}

.SideBarContainer button p {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 0 0 7px;
}

.SideBarContainer button span {
  display: block;
}

.SideBarList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 3px 0px 3px 0px !important; */
  padding: 0px !important;

}

.SideBarList li span {
  font-size: 14px;
  padding-left: 7px;
}

.SideBarList li {
  display: flex;
  gap: 10px;
  padding: 6px 16px;
  width: 100%;
  cursor: pointer;
  color: rgba(93, 108, 135, 1);
}

.SideBarList li:hover {
  background-color: rgba(255, 241, 243, 1);
}

.SideBarArrow {
  position: absolute;
  right: 10px;
}

.SideBarArrow i {
  font-size: 14px;
}

.profileHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 7px 0px 7px 0px;
  position: relative;
  background: white;
  border: 1px solid #e7e7e7;
  margin: 3px;
  border-radius: 10px;
  height: 20%;
}
.profileHeaderStore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* gap: 10px; */
  border-top: 1px solid #e7e7e7;
  /* border-bottom: 1px solid #e7e7e7; */
  margin: 6px 0 0 0;
  height: 75%;
}

.EditIcon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.profileHeader i {
  color: #c51e3a !important;
  font-size: 14px !important;
}

.profileLogo {
  /* display: flex; */
  align-items: center;
  width: 100%;
  display: none;
}
.profileName {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  background: white;
  margin: 4px;
  border-radius: 11px;
  padding: 6px;
  height: 100%;
  width: 100%;
}

.profileLogHeight{
  height: 30% !important;
}

.profilelogheader{
  height: 47%;
  width: 133px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 7px 0px 7px 0px;
  position: relative;
  background: white;
  border: 1px solid #e7e7e7;
  margin: 3px;
  border-radius: 10px;
}
.profileName p {
  display: flex;
  gap: 8px;
  letter-spacing: 1px;
  align-items: center;
}

.profileName p span {
  color: #525252;
  font-size: 14px;
}

.profileName h5 {
  font-weight: 600;
  font-size: 16px;
  /* letter-spacing: 1px; */
}

.CompanyDetails {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  background: white;
  margin: 10px 4px;
  border: 1px solid #e7e7e7;
  border-radius: 11px;
  padding: 6px;
  /* height: 398px;
  width: 260px; */
  position: relative;
  height: 100%;
  width: 100%;
}

@supports (-ms-ime-align: auto) {
  .CompanyDetails {
    height: 500px; /* Adjust this value as needed */
  }
}
.CompanyDetails p {
  display: flex;
  gap: 8px;
  letter-spacing: 1px;
  align-items: center;
  padding-bottom: 2px;
}

.CompanyDetails p span {
  color: #525252;
  font-size: 14px;
}

.CompanyDetails h5 {
  font-weight: 600;
  font-size: 16px;
}

.profileop {
  width: 100%;
  padding: 10px 0 8px 0;
}

.profileop i {
  font-size: 104px;
  position: absolute;
  right: 23px;
  color: #f0f0f0dd;
}

.profileCustomerInfo {
  z-index: 12;
}

.profileop h5 {
  letter-spacing: 1px;
  font-size: 16px;
  text-decoration: underline;
  /* text-transform: uppercase; */
  /* color: white; */
}

.profileLogo img {
  /* width: 100%; */
  /* width: 50%; */
  height: 29px;
}

.profileDropdown {
  padding: 0 !important;
  border-radius: 0px !important;
  background-color: #e7e7e7 !important;
  border: 1px solid #eab6b6 !important;
  /* display: block; */
  padding: 10px !important;
  border-radius: 10px !important;
  background: #f8f8f8 !important;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.18);
  height: 82vh;
display: flex;
flex-direction: column;
}

.profilelogOut i {
  color: #c51e3a !important;
  font-size: 18px !important;
}

.profilelogOut {
  height: 5%;
  display: flex;
  letter-spacing: 1px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  font-weight: 600;
  border-radius: 0px 0px 6px 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e7e7e7;
  width: 100%;
}

.profilelogOut:hover {
  /* background-color: #fff1f3; */
  background-color: #d4d4d4;
  border-top: 1px solid #e7e7e7;
}

.customtooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.socialLInks {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.socialLInks span {
  cursor: pointer;
}

.socialLInks span svg {
  width: 28px !important;
  height: 40px !important;
}



@media (max-width:2561px) and (min-width:1991px) {
  .MainHeaderLogo img{
    width: 168px;
    height: 68px;
  }

  .avatarBox{
    width: 60px;
    height: 60px;
  }

  .ProfileButton span i{
     font-size: 26px !important;
  }
  .searchContainer{
    font-size: 38px;
  }
  .profileHeader{
    width: 391px;
  }

  .profileName h5{
    font-size: 28px;
    padding-bottom: .5rem;
  }

  .profileName p span{
    font-size: 22px;
  }

  .profileop h5{
    font-size: 28px;
  }

  .CompanyDetails{
    padding: 16px;
  }
  .CompanyDetails h5{
    font-size: 26px;
  }

  .CompanyDetails p span{
    font-size: 21px;
  }
  .socialLInks span svg{
    width: 57px !important;
    height: 45px !important;
  }
  .profilelogOut{
    font-size: 24px;
  }
}

@media (max-width:1901px) and (min-width:1201px) {
  .SideBarWrapper.open {
    width: 12%;
  }
  .RightContent{
    width: 88%;
  }

  .AccordionSection button span {
    font-size: 16px;
    
  }

  .SideBarContainerScroll {
   
    max-height: 84vh;
  }

  .AccordionSection {
    margin-bottom: 12px;
  }
  
  .profileHeader {

    width: 310px ;
  }

  
  
  
}

@media (max-width: 1200px) {
  .MainHeaderWr {
    gap: 0.5rem;
  }
  .MainHeaderLogo img {
    width: 110px;
    height: 40px;
  }
  .searchContainer {
    font-size: 26px;
  }
}

@media (max-width: 991px) {
  .MainHeaderLogo img {
    width: 93px;
    height: 37px;
  }
  .RightContent{
    width: 82%;
  }
  .AccordionSection button span{
    font-size: 13px;
  }

  .SideBarWrapper.open {
    width: 29%;
  }
  .SideBarList li{
    padding: 3px 16px;
  }

  .SideBarList li span{
    font-size: 12px;
  }
  
  .searchContainer {
  font-size: 24px;
  }

  .ProfileButton span i{
    font-size: 19px;
  }
  .SideBarContainerScroll{
    max-height: 82vh;
  }

  .CompanyDetails{
    height: 480px;
  }

  .AccordionSection{
    margin-bottom: 12px;
  }

  
}

@media (max-width:767px) {
  .searchContainer {
    font-size: 20px;
    }  
}
