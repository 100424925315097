.StorelistPage {
  margin: 39px 7px 0 10px;
  position: relative;
}

.StorelistTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #d8d0d0;
  overflow-x: auto;
  /* min-height: 76vh; */
  min-height: 78vh;
}

.TableLocComponent {
  width: 100%;
  overflow-x: auto;
}

.TableLocComponentHeader {
  background: rgba(249, 250, 251, 1);
  color: rgba(107, 114, 128, 1);
  font-weight: 500;
  font-size: 14px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid #e7e7e7;
  position: sticky;
  top: 0px;
}

.TableLocComponent th:first-child {
  border-top-left-radius: 10px;
  min-width: 185px;
}

.TableLocComponent td:first-child {
  min-width: 185px;
}

.TableLocComponent th:last-child {
  border-top-right-radius: 10px;
}

.TableLocComponent th,
.TableLocComponent td {
  padding: 10px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 1px;
  color: rgba(107, 114, 128, 1);
}

.TableLocComponent tr {
  border-bottom: 1px solid #e7e7e7;
}

.TableLocComponent tbody tr {
  height: 51px;
}

.TableLocComponent tbody tr:nth-child(odd) {
  background: #efebeb;
}

.TableLocComponent tbody tr:nth-child(even) {
  background: #f9f9f9;
}

.TableLocName {
  width: 240px;
}

.TableLocNum {
  width: 140px;
}

.PageWrapper {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageWrapper div {
  width: 35px !important;
  height: 35px !important;
}

.NoImageContainer img {
  width: 240px;
}

.tableWidth {
  min-width: 225px;
}

.storeListContainer {
  display: flex;
  gap: 10px;
}

.tableRow {
  cursor: pointer;
}

.storeCard {
  box-shadow: 0px 3.158px 6.315px 0px #d3dae2;
  border: 0.902px solid var(--outline, #e0e6ed);
  border-radius: 4.962px;
  background: var(--White, #fff);
  padding: 18px 18px 18px 18px;
  min-width: 243px;
}

.storeLogo {
  width: 155px;
}

.storeLogo img {
  width: 100%;
}

.cardData {
  text-align: left;

  padding: 20px 0 0 0;
}

.cardData h1 {
  font-size: 18px;
  font-weight: 600;
}

.storeButton button {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0 0 0;
  background-color: rgba(197, 30, 58, 1);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.storeButton button:hover {
  background-color: rgb(162 38 59);
}

.ModalPopupBody {
  height: 525px;
}

.StorelocTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e7e7e7;
  overflow-x: auto;
  min-height: 56vh;
}

.bannerComponent {
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bannerContent {
 position: relative;
}

.closeBanner{
  position: absolute;
  right: 14px;
  top: 6px;
  cursor: pointer;
}

.bannerBody{
  border-radius: 7px;
    overflow: hidden;
    background-color: #fff;
    padding-left: 0px;
    padding-right: 0px;
    -webkit-box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.9);
    box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.9);
}

.bannerImage{
  max-width: 100%;
  height: auto;
  display: block;
}

.bannerInfo{
  padding: 1.5rem;
}

@media (max-width: 2561px) and (min-width: 1991px) {
}

@media (max-width: 1800px) {
  .StorelistPage {
    margin: 17px 7px 0 10px;
  }
  .StorelistTable {
    min-height: 79vh;
  }
}

@media (max-width: 1700px) {
  .StorelistTable {
    min-height: 77vh;
  }
}

@media (max-width: 991px) {
  .StorelistTable {
    min-height: 76vh;
  }
}

@media (max-width: 787px) {
  .StorelistTable {
    min-height: 78vh;
  }
}

@media (max-width: 360px) {
  .StorelistPage {
    margin: 16px 7px 0 10px;
  }
  .StorelistTable {
    min-height: 80vh;
  }
}
