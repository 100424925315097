.custom-datepicker-container .react-datepicker__day--selected, 
.custom-datepicker-container .react-datepicker__day--in-selecting-range, 
.custom-datepicker-container .react-datepicker__day--in-range, 
.custom-datepicker-container .react-datepicker__month-text--selected, 
.custom-datepicker-container .react-datepicker__month-text--in-selecting-range, 
.custom-datepicker-container .react-datepicker__month-text--in-range, 
.custom-datepicker-container .react-datepicker__quarter-text--selected, 
.custom-datepicker-container .react-datepicker__quarter-text--in-selecting-range, 
.custom-datepicker-container .react-datepicker__quarter-text--in-range, 
.custom-datepicker-container .react-datepicker__year-text--selected, 
.custom-datepicker-container .react-datepicker__year-text--in-selecting-range, 
.custom-datepicker-container .react-datepicker__year-text--in-range {
  background-color: #c51e3a !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon{
    padding: 0.6rem !important;
    font-size: 12px !important;
    color: gray !important;
}

.customdatepicker{
  width: 120px;
  padding: 2px 0px 1px 4px;
  font-size: 13px;
  font-family: Arial, sans-serif;
  border: none;
  border-bottom: 2px solid #c51e3a;
  outline: none;
  /* background-color: #f5e6e9; */
  transition: border-color 0.3s;
}

.customdatepicker:focus {
    border-bottom: 2px solid #c51e3a !important;
}

