.PageWrapper {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.PageWrapper div {
  width: 35px !important;
  height: 35px !important;
}

.datePickerContainer {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #6e7079;
  align-items: center;
  padding: 6px 0px 8px 12px;
  display: flex;
  background-color: #f5f5f5;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
}

.SubmitBtn {
  margin-right: 1rem;
}

.Btn {
  background: rgba(197, 30, 58, 1);
  color: #ffffff;
  font-size: 14px;
  border: none;
  box-shadow: 0px 1.06px 2.12px 0px #0000000d;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: 500;
  margin-left: 30px;
  letter-spacing: 1px;
}

.datePickerContainer input[type="date"] {
  border: none;
  background: none;
  width: 10rem;
  color: #6e7079;
  background: #f5f5f5;
  border-bottom: 2px solid #6e7079;
  padding: 4px 6px;
  margin-right: 30px;
}

.datePickerContainer input[type="checkbox"] {
  margin-right: 8px;
}

.datePickerContainer label {
  color: #222222;
  font-size: 14px;
  margin-right: 6px;
}

.OutstandingDetailTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e7e7e7;
  overflow-x: auto;
  height: 65vh;
}

.TotalWrapper {
  position: absolute;
  bottom: 0rem;
  left: 0;
  display: flex;
  width: 100%;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}

.TotalWrapper p {
  padding: 10px;
  text-align: left;
}

.TotalHeading {
  width: 29.5rem !important;
}

.TotalAmountDiv {
  display: flex;
  font-size: 15px;
}

.amounttotal {
  width: 145px;
  text-align: right !important;
}

.check {
  margin-left: 7rem;
}
