.user-box {
  position: relative;
}

.circle-gray {
  background-color: #979595;
}

.modal-backdrop {
  display: none !important;
}

.user-box input {
  width: 100%;
  padding: 10px 0 !important;
  font-size: 16px;
  margin-bottom: 30px;
  border: none !important;
  border-bottom: 1px solid #e7e7e7 !important;
  outline: none !important;
  background: transparent;
}

.user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0 !important;
  font-size: 16px;
  pointer-events: none !important;
  transition: 0.5s;
}

.user-box input:focus ~ label,
.user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.addressform form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px !important;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
}

/* Progress bar container
.progress-bar-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  position: relative;
}




.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 40px;
}

.circle {
  width: 14px;
    height: 14px;
  border-radius: 50%;
  background-color: #ddd; 
  border: 2px solid #ccc; 
  z-index: 1;
}

.circle-gray {
  background-color: #aaa; 
}

.line {
  width: 2px; 
  height: 40px; 
  background-color: #ddd; 
  position: relative;
  z-index: 0;
}

.status-label {
  margin-top: 8px;
  font-size: 14px;
  color: #333;
  text-align: center;
  word-break: break-word;
}

.circle-active {
  background-color: rgb(252, 103, 49);
  border-color: rgb(252, 103, 49);
}

.line-active {
  background-color: rgb(252, 103, 49);
} */

.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 388px;
  overflow-y: auto;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ddd;
  border: 3px solid #ccc;
}

.circle-active {
  background-color: #fc6731; /* Example active color */
  border-color: #fc6731;
}

.circle-gray {
  background-color: #aaa;
}

/* Default line between statuses */
.line {
  width: 2px;
  height: 40px;
  background-color: #ddd; /* Default line color */
  position: relative;
  z-index: 0;
}

/* Grey line for the last status in case of single status */
.line-grey {
  width: 2px;
  height: 40px;
  background-color: #ddd; /* Grey color */
  position: relative;
  z-index: 0;
}
.status-date{
  font-size: 11px;
    color: grey
}

/* Active line when in progress */
.line-active {
  background-color: rgb(252, 103, 49); /* Active line color */
}



.status-label {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.text-muted {
  color: #999;
}
