.selectClass {
  width: 288px !important;
  height: 36px !important;
}

.customdrop {
  width: 130px !important;
}

.selectClass .css-1jqq78o-placeholder {
  font-size: 14px;
}

.selectClass .css-1dimb5e-singleValue {
  color: #6b7280 !important;
  font-size: 12px !important;
  letter-spacing: 1px !important;
  text-align: left !important;
}

.selectClass .css-1nmdiq5-menu {
  color: #6b7280 !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
  text-align: left !important;
}

.selectClass .css-13cymwt-control {
  border-radius: 0.375rem !important;
}

#dropdown {
  appearance: none !important;
}

@media (max-width: 991px) {
  .selectClass {
    width: 181px !important;
  }
}
