.PageWrapper {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageWrapper div {
  width: 35px !important;
  height: 35px !important;
}

.NoImageContainer {
  position: absolute;
  top: 17rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.NoImageContainer img {
  width: 180px;
  padding-top: 5rem;
}

.NoImageContainer h6 {
  letter-spacing: 1px;
  font-size: 2rem;
  color: #222222;
}

.LedgerDetailWrapper {
  width: 100%;
  height: 79vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.LedgerDetailTable {
  height: 66vh;

  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e7e7e7;
  overflow-x: auto;
}
.FirstRow {
  width: 32rem !important;
}

.SubmitBtn {
  margin-right: 1rem;
}

.datePickerContainer {
  font-style: normal;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  color: #6e7079;
  align-items: center;
  padding: 6px 0px 8px 12px;
  display: flex;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e7e7e7;
}

.dateInput {
  border: none;
  background: none;
  width: 10rem;
  color: #6e7079;
  background: #f5f5f5;
  border-bottom: 2px solid #6e7079;
  margin-right: 30px;
  padding: 4px 6px;
}

.dateInput:focus-visible {
  outline: none;
}

.dateText {
  color: #222222;
  font-size: 14px;
  margin-right: 6px;
}

.Btn {
  background: rgba(197, 30, 58, 1);
  color: #ffffff;
  font-size: 14px;
  border: none;
  box-shadow: 0px 1.06px 2.12px 0px #0000000d;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: 500;
  letter-spacing: 1px;
}

.PageWrapper ::-ms-input-placeholder {
  font-size: 14px !important;
  padding-left: 5px !important ;
}

.PageWrapper ::placeholder {
  font-size: 14px !important;
  padding-left: 5px !important ;
}

.firstColRow {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 5rem !important;
}
.rowLoader {
  padding-top: 2px;
}

.rowLoader div {
  width: 20px;
  height: 20px;
}

.eyeIcon i {
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
}

.TotalWrapper {
  position: absolute;
  bottom: 0rem;
  left: 0;
  display: flex;
  width: 100%;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}

.TotalWrapper p {
  padding: 10px;
  text-align: left;
  color: rgba(107, 114, 128, 1);
  
  width: 100%;
}

.TotalHeading {
  width: 43.8125rem !important;
  font-size: 16px !important;
  color: #222222 !important;
  letter-spacing: 1px;
}

.TotalAmountDiv {
  display: flex;
  /* font-size: 18px; */
}

.amoutn {
  width: 115px !important;
  text-align: right !important;
  color: black !important;
  font-size: 16px;
}

.dropdownlistchoice {
  max-width: 70px !important;
  min-width: 61px !important;
  border-radius: 0px !important;
  padding: 0 !important;
}

.checkLis {
  border-bottom: 1px solid #e7e7e7 !important;
}

.checkListPDF {
  color: grey !important;
  font-size: 16px !important;
  padding: 2px 11px !important;
  cursor: pointer;
}

.checkListPDF:hover {
  background-color: #e7e7e7;
}
