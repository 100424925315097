.PaymentFiltersColumn {
  display: flex;
  justify-content: space-between;
  padding: 8px 0.2rem;
  background: rgb(241 241 241);
  gap: 0.5rem;
}

.PaymentMthod {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.PaymentFiler {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}
.PAYMENTFields {
  display: flex;
  gap: 0.5rem;
}

.PaymentHistorySearch {
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px;
  box-shadow: 0px 1.06px 2.12px 0px #0000000d;
  border: 1.06px solid #d1d5db;
  width: fit-content;
  max-width: 125px;
  border-radius: 6px;
  color: #6b7280;
  height: 36px;
  background-color: white;
  position: relative;
}

.chequno {
  max-width: 176px;
}
.PaymentHistorySearch input {
  outline: none;
  border: none;
  width: 100%;
}

.PaymentAdjustButtons {
  display: flex;
  gap: 0.2rem;
  justify-content: flex-end;
  padding-right: 0.8rem;
}

.ReceiptlistTable {
  width: 100%;
  overflow-x: auto;
  height: 51vh;
}

.ReceiptlistTable table {
  overflow-x: scroll;
  width: 100%;
  position: relative;
}

.ReceiptlistTable table thead {
  position: sticky;
  top: 0px;
  background: rgba(249, 250, 251, 1);
  border-top: 1px solid #e7e7e7;
}

.tableSmall {
  min-width: 110px;
}

.tableWidth {
  min-width: 225px;
}

.UpdateFooter {
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
}
