.drawer {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  width: 380px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  z-index: 1000;
}

.revereData{
  height: 406px;
}

.drawer.open {
  right: 0;
}

.drawerContent {
  position: relative;
  height: 100%;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  font-size: 24px;
  padding: 0;
  cursor: pointer;
  margin-bottom: 20px;
 
}

.closeButton i{
  color: black;
}

.drawerBody {
  text-align: left;
}

.Heading {
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid #e7e7e7;
  padding: 7px 0 6px 9px;
}

.CartContent {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 7px 0 6px 9px;
  background: #f5f5f5;
  max-height: 54vh;
  overflow-y: auto;
}

.CreditContent {
  display: flex;
  flex-wrap: wrap;
  background: #f5f5f5;
  max-height: 61vh;
  overflow-y: auto;
  padding: 0.5rem 1rem !important;
}
.CreditRow {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 10px;
}

.CreditSummaryBox {
  display: flex;
  flex-direction: column;
  width: calc(50% - 0.5rem);
  box-sizing: border-box;
  padding: 10px;
}

.Credit h6 {
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 172px;
  white-space: nowrap;
}

.Credit p {
  font-size: 14px;
}

.Address {
  border-bottom: 1px solid rgb(255 216 222);
  padding-bottom: 5px;
}

.ItemName {
  font-size: 14px;
  padding-bottom: 18px;
}

.cartItem {
  background: #fff;
  width: 263px;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  padding: 9px 9px 9px 9px;
}

.CartSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quantityControl {
  display: flex;
  align-items: center;
}

.quantityControl span {
  font-size: 14px;
}

.quantityButton {
  background: rgba(197, 30, 58, 1);
  border: none;
  font-size: 14px;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 5px;
}

.quantityButton i {
  color: #fff;
  padding: 4px;
}

.MRP {
  font-size: 14px;
  font-weight: 600;
}

.CheckoutBody {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.BillBody {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
  padding: 10px 10px 0 10px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: #fff1f3;
  left: 0px;
  border-top: 1px solid rgb(255 216 222);
}
.BillBody h2 {
  font-size: 16px;
  font-weight: 600;
}
.Address h5 {
  font-size: 14px;

  font-weight: 600;
  color: rgba(69, 69, 69, 1);
}

.Address p {
  font-size: 14px;
}

.Price {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.Price h3 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(69, 69, 69, 1);
}

.Price p {
  font-weight: 600;
}

.TotalAmount {
  display: flex;
  justify-content: space-between;
}

.TotalAmount p {
  font-weight: 600;
}

.TotalAmount h3 {
  font-size: 15px;
  font-weight: 400;
}

.BillButton button {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0 0 0;
  background-color: rgba(197, 30, 58, 1);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.BillButton button:hover {
  background-color: rgb(162 38 59);
}

.OrderImage {
  width: 80px;
  height: 80px;
}

.OrderSummaryBox img {
  width: 100%;
  height: 100%;
}

.OrderSummaryBox {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.Product{
  padding: 0 1rem;
}

.Product h6 {
  font-size: 12px;
  font-weight: 600;
}

.Product p {
  font-size: 14px;
}

.ProductPrice {
  font-size: 14px;
  font-weight: 600;
  color: #cd030e;
}
