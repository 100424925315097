.ProductDetailWrapper {
  padding: 8px 8px 0px 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ProductDetailTop {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.outOfStock {
  background-color: rgba(255, 241, 243, 1);
}

.PageWrapper {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0%;
}

.PageWrapper div {
  width: 35px !important;
  height: 35px !important;
}

.ProductTopCards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.ProductExportButton {
  padding-right: 2rem;
}

.ProductTopComponent {
  background: rgba(248, 248, 248, 1);
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 16px 17px 16px 17px;
  min-width: 195px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}

.ProductTopComponent h2 {
  font-size: 20px;
  color: rgba(222, 172, 23, 1);
  font-weight: 700;
}

.ProductTopComponent p {
  color: rgba(156, 158, 173, 1);
  font-weight: 500;
  font-size: 14px;
}

.ProductDetailSearch {
  display: flex;
  gap: 15px;
  align-items: center;
}

.SearchProduct {
  display: flex;
  border: none;
  position: relative;
}

.SearchIcon {
  position: absolute;
  right: 25px;
  top: 50%;
  color: #6b7280;
  font-size: 14px;
  background-color: white;
  transform: translateY(-50%);
}

.DetailButton1 {
  background: #db551b;
  color: #ffffff;
  font-size: 14px;
  border: 1px solid #db551b;
  box-shadow: 0px 1.06px 2.12px 0px #0000000d;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: 500;
}

.DetailButton2 {
  background: rgba(242, 222, 213, 1);
  color: #db551b;
  font-size: 14px;
  border: 1px solid #db551b;
  box-shadow: 0px 1.06px 2.12px 0px #0000000d;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: 500;
}

.SearchDropdownBox {
  position: relative;
}

select {
  appearance: none !important;
}

.NoImageContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.NoImageContainer img {
  width: 180px;
  padding-top: 5rem;
}
.NoImageContainer h6 {
  letter-spacing: 1px;
  font-size: 2rem;
  color: #222222;
}

.SearchDropdownBox svg {
  content: "";
  position: absolute;
  right: 25px;
  height: 20px;
  width: 4px;
  background-color: #808080;
  top: 10px;
}

.indicatorSeparator {
  position: absolute;
  display: block;
  right: 15%;
  height: 20px;
  width: 0.5px;
  background-color: #cccccc;
  top: 50%;
  transform: translateY(-50%);
}

.SearchDropdown,
.ProductSearchBar {
  width: 288px !important;
  height: 36px !important;
}

.ProductDetailTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e7e7e7;
  overflow-x: auto;
  height: 59vh;
  position: relative;
}

.TableComponent {
  width: 100%;
  overflow-x: auto;
}

.TableComponentHeader {
  background: rgba(249, 250, 251, 1);
  color: rgba(107, 114, 128, 1);
  font-weight: 500;
  font-size: 14px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid #d8d0d0;
  position: sticky;
  top: 0px;
}

.TableComponent th:first-child {
  border-top-left-radius: 10px;
  min-width: 159px;
}

.TableComponent td:first-child {
  min-width: 159px;
}

.TableComponent th:last-child {
  border-top-right-radius: 10px;
}

.TableComponent th,
.TableComponent td {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  width: fit-content;
  color: rgba(107, 114, 128, 1);
}

.TableComponent tr {
  border-bottom: 1px solid #d8d0d0;
}

.TableName {
  width: 210px !important;
}

.TableNum {
  width: 146px !important;
}

.InActiveProduct {
  color: rgba(157, 23, 77, 1);
  border: 1px solid rgba(249, 168, 212, 1) !important;
  background-color: rgba(252, 231, 243, 1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: fit-content;
  padding: 0 10px 0 9px;
}

.ActiveProduct {
  color: rgba(6, 95, 70, 1);
  border: 1px solid rgb(71 163 115) !important;
  background-color: rgba(209, 250, 229, 1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: fit-content;
  padding: 0 10px 0 9px;
}

.ProductImageContainer {
  height: 210px;
  border: 5px solid #c51e3a17;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 16px;
}

.ProductImageContainer img {
  height: 100%;
}

.ProductDetailModal p {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px !important;
  text-align: left;
}

.ProductDetailModal p span {
  font-size: 14px;
}

.ProductDetailModal p span:first-child {
  font-weight: 600;
  min-width: 100px;
}

.ProductDetailModal p span:last-child {
  min-width: 120px;
}

.DropdownList {
  position: absolute;
  top: 39px;
  left: 0;
  background-color: #fff;
  color: black;
  border: 1px solid #ddd;
  padding: 10px;
  width: 289px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 999;
  border-radius: 6px;
}

.DropdownList li {
  padding: 6px 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
}

.DropdownList li:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.SelectedItem {
  background-color: #f0f0f0;
}

.ProductRowDetails:hover {
  background-color: #d7d9de57;
  cursor: pointer;
}

.dropDownQty {
  width: 250px;
  padding: 5px 2px;
}

@media (max-width: 2561px) and (min-width: 2200px) {
  .TableComponent th,
  .TableComponent td {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .ProductDetailTable {
    height: 50vh;
  }
}

@media (max-width: 1400px) {
  .ProductDetailTable {
    height: 44vh;
  }
}

@media (max-width: 1200px) {
  .ProductDetailTable {
    height: 46vh;
  }
}

@media (max-width: 991px) {
  .ProductDetailTop {
    display: none;
  }
  .ProductTopComponent {
    padding: 9px 10px;
    min-width: 177px;
  }

  .ProductTopComponent h2 {
    font-size: 16px;
  }

  .ProductSearchBar {
    width: 191px !important;
  }

  .ProductDetailTable {
    height: 70vh;
  }

  .TableComponent th,
  .TableComponent td {
    font-size: 12px;
  }
}
