.notfounddiv{
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .5rem;
}

.notfounddiv div{
    display: flex;
    align-items: center;
    gap: .5rem;
}

.notfounddiv h1{
    border-right: 1px solid gray;
    padding-right: .5rem;
}

.notfounddiv h6{
    font-size: 21px;
    letter-spacing: 1px;
}

button{
    background: rgba(197, 30, 58, 1);
  color: #ffffff;
  font-size: 14px;
  border: none;
  box-shadow: 0px 1.06px 2.12px 0px #0000000d;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: 500;
  letter-spacing: 1px;
}