.OrderHistorySetup {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.PageWrapper {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageWrapper div {
  width: 35px !important;
  height: 35px !important;
}

.successMsg {
  letter-spacing: 1px;
  font-weight: 600;
  color: rgba(197, 30, 58, 1);
}

.successMsgModal {
  margin: 0 auto !important;
  letter-spacing: 1px;
  font-weight: 600;
  color: rgba(197, 30, 58, 1);
}

.OrderHistoryFilters {
  display: flex;
  justify-content: space-between;
  padding: 8px 0.2rem;
  background: rgb(241 241 241);
}

.OrderHistoryFilterOptions {
  display: flex;
  gap: 1rem;
}

.OrderHistorySearch {
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px;
  box-shadow: 0px 1.06px 2.12px 0px #0000000d;
  border: 1.06px solid #d1d5db;
  width: fit-content;
  border-radius: 6px;
  color: #6b7280;
  height: 36px;
  background-color: white;
}
.dateText {
  color: #222222;
  font-size: 14px;
  margin-right: 6px;
}

.OrderStatusGroup {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.OrderDates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
}

.billingCheck {
  font-size: 12px;
}

.orderModalBody {
  height: 467px !important;
  padding: 0.8rem 1.6rem;
}

.OrderhistoryTable {
  width: 98%;
  border-collapse: collapse;
  border: 1px solid #e7e7e7;
  overflow-x: auto;
  height: 63vh;
  margin-left: 0.8rem;
}

.OrderStatusDropdown {
  height: 36px;
  /* width: 139px; */
  width: 230px;
}

.OrderListHeading {
  text-align: left;
}

.OrderListHeading h1 {
  font-size: 20px;
  font-weight: 600;
  padding: 0 0 0 8px;
}

.OrderStatusDropdown select {
  height: 36px;
}

.OrderHistorySearch input {
  outline: none;
  border: none;
  width: 100%;
}

.OrderHistoryButtons {
  display: flex;
  /* flex-direction: column; */
  gap: 0.2rem;
  padding-right: 0.5rem;
}

.OrderHistorySearchBtn {
  min-width: 150px;
  font-size: 13px;
  border-right: 2px solid #c6c6c6;
}

.OrderHistorySearchBtn button {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 10px;
}

.listConfirmMsg {
  text-align: left !important;
}

.OrderHistorySearchBtn ul li {
  padding: 4px 10px;
  cursor: pointer;
}

.OrderHistorySearchBtn ul li:hover {
  background-color: #f5f5f5;
}

.ProductDetailTable {
  height: 62vh !important;
  overflow-y: auto;
}

.TableComponent {
  width: 100%;
}

.TableComponentHeader {
  background: rgba(249, 250, 251, 1);
  color: rgba(107, 114, 128, 1);
  font-weight: 500;
  font-size: 14px;
  width: 100%;
}

.TableComponent th,
.TableComponent td {
  border-bottom: 1px solid #e7e7e7;
  padding: 6px 8px;
  text-align: left;
  font-size: 14px;
}

.status {
  border-radius: 5px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: fit-content;
  /* padding: 0 10px 0 10px; */
  font-size: 12px;
}

.PendingOrder {
  color: rgb(171 131 11);
  border: 1px solid rgba(222, 172, 23, 1) !important;
  background-color: rgba(255, 234, 170, 1);
}

.ShippedOrder {
  color: rgb(38 99 167);
  border: 1px solid rgba(147, 197, 253, 1) !important;
  background-color: rgba(219, 234, 254, 1);
}

.DeliveredOrder {
  color: rgba(6, 95, 70, 1);
  border: 1px solid rgb(71 163 115) !important;
  background-color: rgba(209, 250, 229, 1);
}

.CancelledOrder {
  color: rgba(153, 27, 27, 1);
  border: 1px solid rgba(252, 165, 165, 1) !important;
  background-color: rgba(254, 226, 226, 1);
}

.DefaultOrder {
  color: rgb(58 58 58);
  border: 1px solid rgb(207 207 207) !important;
  background-color: rgb(243 243 243);
}

.Btn {
  background: rgba(197, 30, 58, 1) !important;
  color: #ffffff !important;
  font-size: 14px !important;
  border: none !important;
  box-shadow: 0px 1.06px 2.12px 0px #0000000d !important;
  border-radius: 5px !important;
  padding: 8px 18px !important;
  font-weight: 500 !important;
  letter-spacing: 1px;
}

.Btn:focus-visible {
  /* background-color:#5c636a; */
  border-color: #565e64 !important;
  box-shadow:  0 0 0 0.25rem rgba(130,138,145, .5) !important;
 
  outline: 0 !important;
}

.BtnSuccess {
  width: 14rem;
}
.OffcanvasHeader {
  justify-content: space-between;
  padding: 0.5rem 1rem !important;
}

.firstcolWidth {
  width: 35px;
}

.OrderStatus {
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  align-items: center;
  gap: 0.2rem;
}
.colWidth {
  width: 55px;
}

.RateCol {
  width: 85px;
  text-align: end;
}

.DetailsTable td,
.DetailsTable th {
  font-size: 13px;
}

.OffcanvasHeaderTitle {
  color: #344054;
  font-weight: bold;
}

.OffcanvasButton {
  font-size: 10px;
  border-radius: 10px;
  padding: 6px 8px;
}

.OffcanvasDate {
  display: flex;
  padding: 0 0rem 7px 0;
  border-bottom: 1px solid #e7e7e7;
  margin: 0 1rem;
}

.OrderDateHeading {
  color: #667085;
  font-size: 12px;
}

.OrderDateHeading span {
  font-weight: bold;
  font-size: 13px;
}

.UpdateBtn {
  background: rgba(197, 30, 58, 1);
  color: #ffffff;
  font-size: 14px;
  border: none;
  box-shadow: 0px 1.06px 2.12px 0px #0000000d;
  border-radius: 5px;
  padding: 8px 18px;
  font-weight: 500;
  padding: 0px 16px;
  font-weight: 500;
  width: 120px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderAmountList h6 {
  font-size: 14px;
}

.TotalAmount {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 0px;
  margin-top: 10px;
}

.modalTitle {
  font-size: 22px !important;
  font-weight: 600 !important;
}

.productName {
  font-size: 16px;
  font-weight: 600;
}

.QtyField {
  display: flex;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  /* width: 70%; */
  width: 30%;
}

.ordersummaryMain {
  display: flex;
  height: 100%;
}

.ProductEmptyPage {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProductEmptyPage div {
  width: 35px;
  height: 35px;
}

.OrderSummHeader {
  padding: 0.5rem 1.5rem !important;
}

.selectedLocation h6 {
  font-size: 14px;
  min-width: 18%;
  font-weight: 600;
}

.selectedPlace h4 {
  font-size: 20px;
  margin: 0;
  min-width: 18%;
  font-weight: 600;
}

.RadioPOint {
  font-size: 13px;
}

.selectedLocation p {
  font-size: 13px;
  line-height: 17px;
}

.orderSummaryModal .modal-dialog {
  z-index: 1000 !important;
  width: 900px !important;
  min-width: 900px !important;
}

.selectedLocation:first-child {
  margin-top: 10px;
}

.selectedLocation {
  gap: 10px;
  padding: 4px 0px;
  align-items: flex-start;
}

.ordersummaryRight {
  width: 56%;
}

.nametu {
  font-size: 13px;
}

.nametu b {
  width: 56px;
}

.formtext {
  border: none !important;
  border-bottom: 1px solid #cedaf4 !important;
  border-radius: 0px !important;
  padding: 0rem 0.3rem 0rem 0.3rem !important;
  color: #26282b !important;
  font-size: 14px !important;
}

.LocationDiv {
  position: relative;
}

.LocationDiv i {
  position: absolute;
  top: 5px;
  right: 16px;
  font-size: 20px;
  cursor: pointer;
  color: rgba(197, 30, 58, 1);
}

.AddressSelect {
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #e7e7e7;
  color: #26282b !important;
}

.formLable {
  color: #6b7280 !important;
  font-size: 13px !important;
}

.createorderfooter {
  padding: 0.3rem 0.4rem !important;
}

.AddAddressModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(115 105 105 / 60%);
  z-index: 1050;
}

.addressbody {
  z-index: 5000 !important;
  padding: 1rem 1rem 0 1rem !important;
}

.addnewaddress {
  color: rgba(197, 30, 58, 1);
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

.Shippingtitle {
  font-size: 16px;
  font-weight: 600;
}

.selectShip {
  font-size: 13px;
}
.checkbox-div label {
  font-size: 14px;
}

.optionwidth {
  max-width: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.labelship {
  font-size: 12px;
  padding-left: 4px;
}

.ordersummaryLeft {
  border-right: 1px solid #e7e7e7;
  padding-right: 15px;
  width: 44%;
}

.ordersummaryRight {
  padding: 0px 0px 0px 20px;
}

.QtyField input,
.QtyField select {
  border: none;
}

.QtyField input {
  background-color: transparent;
  width: 100%;
  padding: 6px 10px;
}

.QtyFieldBox label {
  margin-bottom: 0px;
  font-size: 14px;
  color: #454545;
}

.QtyField select {
  background-color: rgba(245, 245, 245, 1);
  border-radius: 0px 6px 6px 0px;
}

.quantityInput input {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  outline: none;
  padding: 4px 10px;
  max-width: 100px;
}

.RemarkBox label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
  margin-top: 10px;
}

.orderSuccessWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderSuccessContent img {
  width: 350px;
}

.orderSuccessContent {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
}

.quantity-input::-webkit-inner-spin-button,
.quantity-input::-webkit-outer-spin-button {
  -webkit-appearance: auto;
  appearance: auto;
}
