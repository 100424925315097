.LoginWrapper {
  background-color: #fff;
  display: flex;
  gap: 5px;
  height: 100vh;
}

.SignupStepForm {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.SinupField {
  width: 100%;
}

.SignupRequired label::after {
  content: "*";
  color: red;
}

.SignUpOverlay {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #00000046;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.SignUpWrapper {
  position: relative;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 590px;
  min-width: 550px;
  padding-bottom: 20px;
}
.LoginImage {
  width: 64%;
  height: 100vh;
}

.LoginImage img {
  height: 100%;
  width: 100%;
}
.LoginContent {
  position: relative;
  width: 36%;
  display: flex;
  flex-direction: column;
}

.LoginContainer {
  position: relative;
  padding: 0px 33px 0 46px;
  text-align: left;
  margin: 100px 0 0 0;
}

.SignupContainer {
  text-align: left;
  padding: 10px 0 0 40px;
}

.signUpForm {
  text-align: left;
  padding: 0px 0px 12px 16px;
}
.LoginOTPContainer {
  position: relative;
  padding: 29px 28px 0 7px;
  text-align: left;
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #333;
  font-size: 20px;
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CloseButton:hover {
  background-color: #fde6ea;
}

.LoginHeading {
  text-align: left;
  padding: 47px 0px 0 39px;
}

.LoginHeading img {
  height: 45px;
}

.LoginHeading h1 {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.1px;
  padding-top: 10px;
}

.LoginHeadText {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.1px;
  padding: 0 0 40px 0;
}

.LabelClass {
  font-size: 16px;
  font-weight: 500;
}

.SignUpLabel {
  font-size: 14px;
}

.Input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.SignUpInput {
  width: calc(100% - 20px) !important;
  padding: 5px !important;
  margin: 0 !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}

.invalidErr {
  font-size: 10px !important;
}

.SubmitButton {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0 0 0;
  background-color: rgba(197, 30, 58, 1);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.SubmitButton:hover {
  background-color: rgb(162 38 59);
}

.SignUpButton {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 0 0 10px 0;
  background-color: rgba(245, 245, 245, 1);
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.spanSignIn {
  color: rgba(232, 183, 85, 1);
  font-weight: 600;
}

.OtpContainer {
  padding: 20px;
}

.OtpInputContainer {
  display: flex;
  margin-top: 20px;
}

.OtpInput {
  width: 40px;
  height: 40px;
  margin: 5px 5px 0 0;
  text-align: center;
  font-size: 1.2em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.VerificationBodyBottomDisableText {
  text-align: left;
  font-size: 10px;
  padding: 10px 0 0 10px;
  color: rgba(156, 158, 173, 1);
}

.OtpInputWrapper {
  display: flex;
  flex-direction: column;
  width: 175px;
}
.VerificationResendLink {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 0 0 10px;
  color: rgba(222, 172, 23, 1);
  cursor: pointer;
}
.SignupHeading {
  text-align: left;
  padding: 16px 0px 0 16px;
}

.SignupHeading img {
  height: 40px;
}

.SignupHeading h1 {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.1px;
  padding-top: 10px;
}

.LoginCopy p {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: rgba(107, 114, 128, 1);
  text-align: left;
  padding: 0 0px 0 51px;
}

.LoginCopy p span {
  text-decoration: underline;
  cursor: pointer;
}

.LoginCopy p span:hover {
  color: rgb(31 34 41);
}
.StepSwitcher {
  text-align: left;
  padding: 13px 0px 13px 16px;
  display: flex;
  gap: 2rem;
}

.StepSwitcher p {
  display: flex;
  gap: 15px;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
}

.Step {
  color: rgba(141, 141, 153, 1);
}

.Step span {
  background: rgba(225, 225, 230, 1);
  border-radius: 50%;
  border: none;
  color: rgba(141, 141, 153, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

.ActiveStep span {
  background: rgba(222, 172, 23, 1);
  border-radius: 50%;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}
/* 
@media (max-width: 2561px) {
  .LoginContent {
    width: 29%;
  }
  .LoginImage {
    width: 71%;
  }
  .LoginHeading img {
    height: 88px;
  }

  .LoginHeading h1 {
    font-size: 40px;
    padding-top: 20px;
  }

  .LoginHeadText {
    font-size: 37px;
  }

  .LabelClass {
    font-size: 25px;
  }

  .Input {
    height: 55px;
    font-size: 22px;
  }
  .SubmitButton {
    font-size: 22px;
  }
  .LoginCopy p {
    font-size: 1.2rem;
  }

  .LoginOTPContainer p {
    font-size: 25px;
  }

  .OtpInputWrapper {
    width: 309px;
  }

  .OtpInputContainer {
    gap: 16px;
  }

  .OtpInput {
    height: 60px;
    width: 60px;
  }

  .VerificationResendLink {
    font-size: 24px;
  }
}

@media (max-width: 1991px) {
  .LoginHeading img {
    height: 69px;
  }

  .LoginHeading h1 {
    font-size: 34px;
  }

  .LoginHeadText {
    font-size: 32px;
  }

  .LoginContainer{
    margin: 65px 0 0 0;
  }

  .LabelClass{
    font-size: 22px;
  }

  .Input{
    font-size: 19px;
  }

  .LoginCopy p {
    font-size: 0.9rem;
  }



} */


@media (max-width:1441px) {

  .LoginHeading{
    padding: 23px 0px 0 18px;
  }

  .LoginHeading img{
    height: 50px;
  }

  .LoginHeading h1{
    font-size: 25px;
  }

  .LoginContainer{
    padding: 0px 33px 0 18px;
  }

  .LoginHeadText{
    font-size: 25px;
    padding: 0 0 28px 0;
  }

  .LabelClass{
    font-size: 17px;
  }

  .Input{
    height: 35px;
  }

  .SubmitButton{
    font-size: 16px;
  }

  .LoginCopy p{
    font-size: 0.75rem;
    padding: 0 0px 0 20px;
  }

  .LoginOTPContainer p{
    font-size: 18px;
  }

  .OtpInputContainer{
    gap: 6px;
  }

  .OtpInputWrapper{
    width: 203px;
  }

  .OtpInput{
    width: 42px;
    height: 42px;
  }

  .VerificationResendLink{
    font-size: 13px !important;
  }


  
}

@media (max-width: 1199px) {
  .LoginContent {
    width: 36%;
  }
  .LoginImage {
    width: 64%;
    overflow: hidden;
  }
 .LoginHeading h1{
  font-size: 21px;
 }

 .LoginHeadText{
  font-size: 19px;
 }

 .LabelClass{
  font-size: 15px;
 }

 .LoginCopy p{
  padding: 0 40px 0 20px;
 }
}

@media (max-width: 991px) {
  .LoginWrapper{
    gap: 0px;
  }
  .LoginContent {
    width: 80%;
  }

  .LoginHeading h1 {
    font-size: 21px;
  }
  .LoginContainer {
    margin: 45px 0 0 0;
  }
  .LoginHeadText {
    font-size: 20px;
    padding: 0 0 29px 0;
  }
  .LoginCopy p {
    padding: 0 42px 0 21px;
    font-size: 0.65rem;
  }
  .LoginImage {
    width: 686px;
    height: 100vh;
  }
  .LoginImage img {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .LoginImage {
    display: none;
  }
  .LoginContent {
    width: 100%;
  }
  .LoginHeading img {
    height: 71px;
  }
  .LoginHeading h1 {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.1px;
    padding-top: 14px;
  }
  .LoginContainer {
    margin: 70px 0 0 0;
  }
  .LoginHeadText {
    font-size: 31px;
  }
  .LabelClass {
    font-size: 18px;
  }
  .LoginCopy p {
    font-size: 0.85rem;
  }
  .SubmitButton {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .LoginContainer {
    margin: 58px 0 0 0;
  }
  .LoginCopy p {
    font-size: 0.65rem;
  }

  .LabelClass {
    font-size: 15px;
  }

  .SignUpWrapper {
    min-height: 100vh;
    min-width: 100vw;
  }

  .SignupHeading h1 {
    font-size: 20px;
  }

  .SignupStepForm {
    gap: 17px;
  }
  .SignUpLabel {
    font-size: 13px;
  }
  .SignUpInput {
    font-size: 0.75rem;
  }
  .SubmitButton {
    font-size: 15px;
    text-transform: uppercase;
    margin: 0;
  }
  .CloseButton {
    font-size: 13px;
  }
}

@media (max-width: 426px) {

  .LoginHeading img{
    height: 52px;
  }

  .LoginHeading h1{
    font-size: 24px;
  }

  .LoginContainer{
    margin: 31px 0 0 0;
  }
  .LoginHeadText{
    font-size: 22px;
  }
}

@media (max-width: 376px) {
  .LoginHeading {
    padding: 26px 0px 0 21px;
  }

  .LoginHeading img {
    height: 58px;
  }
  .LoginHeading h1 {
    font-size: 26px;
  }
  .LoginHeadText {
    font-size: 25px;
  }
  .LoginContainer {
    margin: 37px 0 0 0;
    padding: 0px 30px 0 20px;
  }

  .LoginCopy {
    padding: 0px 44px 0 24px;
  }

  .LoginCopy p {
    padding: 0 0px 0 0px;
  }
}

@media (max-width: 360px) {
  .LoginHeading {
    padding: 27px 0px 0 16px;
  }

  .LoginHeading img {
    height: 62px;
  }

  .LoginHeading h1 {
    font-size: 22px;
  }

  .LoginContainer {
    margin: 35px 0 0 0;
    padding: 0px 0px 0 19px;
  }

  .LoginHeadText {
    font-size: 22px;
    padding: 0 0 27px 0;
  }
  .LabelClass {
    font-size: 14px;
  }
  .Input {
    padding: 7px;
    margin: 8px 0;
  }
  .SubmitButton {
    margin: 6px 0 0 0;
    font-size: 14px;
  }
  .LoginCopy p {
    padding: 0 17px 0 20px;
    font-size: 0.6rem;
  }

  .ActiveStep span {
    width: 20px;
    height: 20px;
  }
  .StepSwitcher {
    gap: 1rem;
    padding: 22px 0px 13px 16px;
  }
  .StepSwitcher p {
    gap: 5px;
  }

  .OtpInputWrapper {
    display: flex;
    flex-direction: column;
    width: 175px;
  }
}
